import React from "react";
import Cube from "../components/cube";
import Cartel from "../components/cartel";
import { Carousel } from "react-responsive-carousel";
import PorfolioPc from "../images/portfolioPc.png";
import PorfolioPhone from "../images/portfolioPhone.png";
import csmac from "../images/CSMAC.png";
import csipho from "../images/CSIPHO.png";
import comac from "../images/COMAC.png";
import coipho from "../images/COIPHO.png";
import mdmac from "../images/MdMAC.png";
import mdipho from "../images/MDIPHO.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./portfolio.scss";

const portfolio = (props) => {
  const { dark } = props;

  return (
    <Cube dark={dark} id={"portfolio"}>
      <div className="portfolio">
        <Cartel content={"Últimos trabajos"} />
        <Carousel
          className="carosel"
          showThumbs={false}
          emulateTouch={true}
          showStatus={false}
          // infiniteLoop={true}
        >
          <div>
            <img src={csmac} alt="Web Calada de sabor Pc" />
          </div>
          <div>
            <img src={csipho} alt="Web Calada de sabor Movil" />
          </div>
          <div>
            <img src={comac} alt="Web Optica Pc" />
          </div>
          <div>
            <img src={coipho} alt="Web Optica Movil" />
          </div>
          <div>
            <img src={mdmac} alt="Web MD Forja Pc" />
          </div>
          <div>
            <img src={mdipho} alt="Web MD Forja Movil" />
          </div>
          <div>
            <img src={PorfolioPc} alt="Porfolio Personal Pc" />
          </div>
          <div>
            <img src={PorfolioPhone} alt="Porfolio Personal Movil" />
          </div>
          <div className="carouselText">
            <div>
              <h3>
                Las tecnologias utlizadas en estos proyectos son Wordpress y
                React JS / Gatsby JS
              </h3>
              <h4>Estoy preparando proyectos anteriores para añadirlos.</h4>
            </div>
          </div>
        </Carousel>
      </div>
    </Cube>
  );
};

export default portfolio;
